const caseStudies = document.getElementById('caseStudies');
const links = caseStudies.querySelectorAll('ul li');
const studies = caseStudies.querySelectorAll('.case-study');

links.forEach((link, i) => {
  link.addEventListener('click', () => {
    links.forEach((link) => {
      link.classList.add('fade-more');
    });

    link.classList.remove('fade-more');

    studies.forEach((study) => {
      study.classList.add('dis-n');
    });

    studies[i].classList.remove('dis-n');
  });
});
